import React from 'react';
import { COLOR } from '@latitude/core/utils/constants';
import RatesAndFeesComponent from '@latitude/rates-and-fees';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Link } from '@latitude/link';

const heading = 'Rates, Fees and Conditions';

const RatesAndFees = () => (
  <div id="rates-and-fees">
    <AnalyticsLocationProvider location={heading}>
      <RatesAndFeesComponent
        id="rates"
        css={`
          button.link-block {
            background-color: ${COLOR.GREY5};
            cursor: pointer;
          }
          .accordion-custom .accordion__row {
            padding: 5px 0;
            margin: 0 30px;
          }
            div[class*='RatesListBranded__RatesList']{
              &::before, &::after {
                height:0;
                margin:0;
                background: none;
              }
            }
            [class*='RatesAndFees__Title'] {
                line-height: 20px;
                margin-bottom: 0;
              }
        `}
        title="Rates, Fees and Conditions"
        ratesListProps={{
          data: [
            
          ]
        }}
        accordionProps={{
          items: [
            {
              id: 'other-fees',
              title: 'Rates, Fees and charges',
              content: (
                <div className="accordion-custom">
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Cash rate​</strong>
                    </p>
                    <p className="w-100">29.99% p.a.</p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Annual Percentage Rate (APR)</strong>
                    </p>
                    <p className="w-100">29.99% p.a.</p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Account servicing fee (charged monthly)​</strong>
                    </p>
                    <p className="w-100">
                      $10.95
                    </p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Late Fee</strong>
                    </p>
                    <p className="w-100">
                      $45
                    </p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>ATM withdrawal fee​</strong>
                    </p>
                    <p className="w-100">
                    Greater of 3.5% or $4​
                    </p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>EFTPOS withdrawal fee​</strong>
                    </p>
                    <p className="w-100">
                      $0.85
                    </p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>BPAY cash advance fee​</strong>
                    </p>
                    <p className="w-100">
                    Greater of 3.5% or $4​
                    </p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Payment handling fee​​</strong>
                    </p>
                    <p className="w-100">
                      <AnalyticsLocationProvider location="Payment handling fees">
                        $0 when you pay via the{' '}
                        <Link href="/mobile-app/">Latitude App</Link>
                        {', '}
                        <Link href="https://online.gemvisa.com.au/access/login">
                        Latitude Service Centre
                        </Link>
                        , mail, or direct debit
                        <br /> <br />
                        $1.95 (online using BPAY<sup>®</sup>)
                        <br /> <br />
                        $3.95 (in person at Australia Post)
                        <br /> <br />
                        ® Registered to BPAY Pty Ltd ABN 69 079 137 518
                      </AnalyticsLocationProvider>
                    </p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Paper statements</strong>
                    </p>
                    <p className="w-100">
                      <AnalyticsLocationProvider location="Paper statements">
                        $5.00
                        <br />
                        You can avoid this fee by opting to receive statements
                        online. Simply login to the{' '}
                        <Link href="https://online.gemvisa.com.au/access/login">
                        Latitude Service Centre
                        </Link>
                        .
                      </AnalyticsLocationProvider>
                    </p>
                  </div>
                </div>
              )
            },
            {
              id: 'other-information',
              title: 'Reference documents',
              content: (
                <div className="accordion-custom">
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100 col-sm-9">
                    Please refer to these documents to help you understand the product further. Useful forms also available for requests.
                    </p>
                    <p className="w-100 col-sm-3">
                      <Link href="https://assets.latitudefinancial.com/legals/conditions-of-use/creditline-au/cou.pdf">
                      Conditions of Use  
                      </Link>
                      <br />
                      <Link href="https://www.latitudefinancial.com.au/forms/">
                      Useful forms
                      </Link>
                    </p>
                  </div>
                </div>
              )
            }
          ]
        }}
      />
    </AnalyticsLocationProvider>
    <div
      css={`
        text-align: center;
        margin: auto;
        padding-bottom: 30px;
        max-width: 800px;
      `}
    >
      Note: Effective from 31 Oct 2024, EFTPOS functionality is being removed from your credit card account and will not be available at point-of-sale and ATM withdrawals.
    </div>
  </div>
);

export default RatesAndFees;
